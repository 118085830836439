import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { EasyGiving } from 'components/ui-elements/EasyGiving';

import { CampaignsHomeList } from './CampaignsHomeList';
import { FundraiserSection } from './FundraiserSection';
import { ItemsHomeList } from './ItemsHomeList';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${theme.maxWidthContainer}px;
  width: 100%;
  margin: 0 auto;
`;

export const Home = () => {
  return (
    <HomeContainer>
      <CampaignsHomeList />
      <FundraiserSection />
      <ItemsHomeList />
      <EasyGiving />
    </HomeContainer>
  );
};
