import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import WhiteButton from 'components/buttons/WhiteButton';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { orderService } from 'services/order/orderService';
import { PurchaseDto } from 'services/order/orderService.dto';

const REFUND_REASON_LENGTH_LIMIT = 20000;

const Container = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
`;

const StyledTextarea = styled.textarea`
  padding: 12px;
  width: 100%;
  background-color: ${theme.color.lighterGray};
  border: none;
  border-radius: 5px;
  height: 100px;
  ::placeholder {
    color: ${theme.color.placeholderGrey};
  }
`;

interface Props {
  order: PurchaseDto;
  hidePopup: () => void;
  onInvalidRequest: () => void;
  onRefundRequested: () => void;
}

export const ReturnAndRefund = ({ order, hidePopup, onInvalidRequest, onRefundRequested }: Props) => {
  const { t } = useTranslation('myProfile');
  const [refundReason, setRefundReason] = useState('');

  const handleSendFeedback = () => {
    orderService
      .requestForRefund(order.id, refundReason)
      .then(() => {
        hidePopup();
        onRefundRequested();
      })
      .catch(onInvalidRequest);
  };

  return (
    <Container data-testid={'return-and-refund-popup'}>
      <H4 data-testid={'header'}>{t('return-and-refund.header')}</H4>
      <TextContainer data-testid={'text-container'}>
        <Text1 data-testid={'description'}>{t('return-and-refund.description')}</Text1>
        <StyledTextarea
          data-testid={'input'}
          onChange={e => setRefundReason(e.target.value)}
          maxLength={REFUND_REASON_LENGTH_LIMIT}
          placeholder={t('return-and-refund.input-placeholder')}
        />
        <Text1 data-testid={'feedback-summary'}>{t('return-and-refund.feedback-summary')}</Text1>
      </TextContainer>
      <ButtonsContainer>
        <WhiteButton data-testid={'send-feedback-btn'} onClick={handleSendFeedback}>
          {t('return-and-refund.send-feedback')}
        </WhiteButton>
        <WhiteButton data-testid={'cancel-btn'} onClick={hidePopup}>
          {t('return-and-refund.cancel')}
        </WhiteButton>
      </ButtonsContainer>
    </Container>
  );
};
