import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H3 } from 'components/typography/Headers';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { ItemDto } from 'services/item/itemService.dto';

import CampaignInfo from './CampaignInfo';
import ConditionInfo from './ConditionInfo';
import ShippingInfo from './ShippingInfo';

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 36px;
  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;

  ${theme.mq.huge} {
    padding: 24px;
  }

  ${theme.mq.desktop} {
    padding: 12px;
  }
`;

const PriceContainer = styled.div`
  margin: 24px 0;
`;

interface Props {
  item: ItemDto;
  campaign?: CampaignDto;
}

export const TrackedItemDetails = (props: Props) => {
  const { item, campaign } = props;

  return (
    <Container>
      <CampaignInfo campaign={campaign} />
      <PriceContainer>
        {!!item.price && <H3 data-testid={'item-price'}>${item.price}</H3>}
        <ConditionInfo item={item} />
      </PriceContainer>
      <ShippingInfo item={item} />
    </Container>
  );
};
