import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';
import confirmedIcon from 'assets/svg/dolar-circle.svg';
import defaultImagePlaceholder from 'assets/svg/profile-placeholder.svg';
import trashIcon from 'assets/svg/trash-icon.svg';
import vipIcon from 'assets/svg/vip-icon.svg';
import { Text1, Text2 } from 'components/typography/Texts';
import { useResponsive } from 'hooks/useResponsive';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { ConversationDto, ConversationLastMessageDto, MessageType } from 'services/message/messageService.dto';
import { formatMessageDate } from 'utils/dateFormatter';
import { convertToThumbnail300 } from 'utils/thumbnails';

import { TrashIconWrapper } from './TrashIconWrapper';

const Container = styled.div<{ isSelected: boolean }>`
  height: 72px;
  display: flex;
  background-color: ${props => `${props.isSelected ? theme.color.lighterGray : theme.color.white}`};
  justify-content: flex-start;
  max-width: 360px;
  gap: 10px;
  padding: 4px;
  border: ${props => `2px solid ${props.isSelected ? theme.color.red : theme.color.white}`};
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  align-items: center;

  ${theme.mq.tablet} {
    max-width: 280px;
    height: 90px;
  }

  ${theme.mq.phone} {
    border: none;
    height: auto;
    overflow: visible;
    background-color: ${props => `${props.isSelected ? theme.color.lighterGray : 'none'}`};
  }

  :hover {
    background-color: ${theme.color.lighterGray};
  }
`;

const TrashIconContainer = styled.div`
  right: 75px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.color.white};
  box-shadow: 0 0 5px ${makeTransparentColor(theme.color.borderGray, 1)};
  cursor: pointer;

  ${Container}:hover & {
    display: flex;
  }

  :hover {
    background-color: ${theme.color.borderGray};
  }
`;

const TrashIcon = styled.img`
  width: 16px;
  height: 20px;
`;

const SenderImageContainer = styled.div`
  position: relative;
`;

const SenderImage = styled.img<{ isRead?: boolean }>`
  border-radius: 30px;
  width: 56px;
  height: 56px;
  object-fit: cover;

  ${theme.mq.phone} {
    border: ${props => `2px solid ${props.isRead ? 'transparent' : theme.color.red}`};
  }
`;

const SenderName = styled(Text1)<{ isRead: boolean }>`
  font-weight: ${props => (props.isRead ? 'normal' : 'bold')};

  ${theme.mq.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const MessageLine = styled(Text2)<{ isRead: boolean }>`
  font-weight: ${props => (props.isRead ? 'normal' : 'bold')};
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${theme.mq.tablet} {
    -webkit-line-clamp: 2;

    font-size: 12px;
    line-height: 16px;
  }
`;

const StyledConfirmedIcon = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;

  ${theme.mq.tablet} {
    bottom: 5px;
    left: 0;
  }
`;

const CategoryImage = styled.img`
  border-radius: 8px;
  width: 56px;
  height: 56px;
  object-fit: cover;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${theme.color.red};
`;

const SenderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  max-width: 210px;

  ${theme.mq.tablet} {
    max-width: 136px;
  }
`;

const VipIcon = styled.img`
  width: 16px;
  height: 16px;
`;

interface ConversationProps {
  lastMessage: ConversationLastMessageDto;
  conversation: ConversationDto;
  isSelected: boolean;
  onClick: () => void;
}

export const Conversation = ({ conversation, lastMessage, isSelected, onClick }: ConversationProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { currentUser } = useGlobalData();
  const sender = conversation.interlocutors[0];
  const isRead = lastMessage.unreadMessageCount === 0;

  const resolveCategoryImage = () => {
    if (conversation.item) {
      return conversation.item.imageUrl;
    } else if (conversation.campaign) {
      return conversation.campaign.imageUrl;
    } else {
      return null;
    }
  };

  const categoryImageUrl = resolveCategoryImage();

  const resolveTextByMessageType = () => {
    if (lastMessage?.type === MessageType.PHOTO) {
      const author = currentUser?.id === lastMessage?.authorId ? t('message:messenger.current-author') : sender.name;
      return t('message:messenger.sent-photo', { author });
    } else if (lastMessage?.type === MessageType.NOTIFICATION) {
      return t(
        `notifications:messenger.${lastMessage?.payload.type}.title`,
        t('notifications:unrecognized-notification-message'),
        lastMessage?.payload.attributes
      );
    }
    return lastMessage.lastMessageContent;
  };

  return (
    <Container data-testid={'conversation'} data-selected={isSelected} isSelected={isSelected} onClick={onClick}>
      {isMobile ? (
        <SenderImageContainer>
          <SenderImage
            data-testid={'sender-image'}
            src={sender.profileImageUrl ? convertToThumbnail300(sender.profileImageUrl) : defaultImagePlaceholder}
            isRead={isRead}
          />
          {sender.fundraiserVerificationCompleted && (
            <StyledConfirmedIcon data-testid={'confirmed-icon'} src={confirmedIcon} />
          )}
        </SenderImageContainer>
      ) : (
        <>
          <SenderImageContainer>
            <SenderImage
              data-testid={'sender-image'}
              src={sender.profileImageUrl ? convertToThumbnail300(sender.profileImageUrl) : defaultImagePlaceholder}
            />
            {sender.fundraiserVerificationCompleted && (
              <StyledConfirmedIcon data-testid={'confirmed-icon'} src={confirmedIcon} />
            )}
          </SenderImageContainer>
          <TextContainer>
            <SenderContainer>
              {!isRead && <Dot data-testid={'red-dot'} />}
              <SenderName data-testid={'sender-name'} isRead={isRead}>
                {sender.name}
              </SenderName>
              {sender.vip && <VipIcon data-testid={'vip-icon'} src={vipIcon} />}
            </SenderContainer>
            <MessageContainer>
              <MessageLine data-testid={'last-message'} isRead={isRead}>
                {resolveTextByMessageType()}
              </MessageLine>
              <MessageLine data-testid={'last-message-date'} isRead={isRead}>
                {lastMessage.lastMessageCreated && formatMessageDate(lastMessage.lastMessageCreated)}
              </MessageLine>
            </MessageContainer>
          </TextContainer>
          {categoryImageUrl && (
            <CategoryImage data-testid={'category-image'} src={convertToThumbnail300(categoryImageUrl)} />
          )}
          <TrashIconWrapper conversation={conversation}>
            <TrashIconContainer>
              <TrashIcon data-testid={'trash-icon'} src={trashIcon} />
            </TrashIconContainer>
          </TrashIconWrapper>
        </>
      )}
    </Container>
  );
};
