import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import CommunityIcon from 'assets/svg/community-icon.svg';
import MoreIcon from 'assets/svg/more-icon.svg';
import MoreIconBlack from 'assets/svg/more-icon-black.svg';
import PosterIcon from 'assets/svg/poster-icon.svg';
import QRIcon from 'assets/svg/qr-icon.svg';
import ReportIcon from 'assets/svg/report-icon.svg';
import ShareIcon from 'assets/svg/share-icon.svg';
import { Text1 } from 'components/typography/Texts';
import ReportPopup from 'features/report/ReportPopup';
import { usePopup } from 'hooks/usePopup';
import { useModal } from 'providers/ModalProvider';
import { AppAreaType } from 'services/notification/notificationService.dto';

import ActionPanelPopover from './ActionPanelPopover';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

const ActionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 24px;

  ${theme.mq.phone} {
    padding: 10px 12px;
  }
`;

const ActionText = styled(Text1)`
  font-weight: 700;
  margin-left: 10px;
`;

const MoreWrapper = styled.div<{ clicked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${props => (props.clicked ? theme.color.pink : theme.color.black)};
  padding: 10px;
`;

interface Props {
  appArea: AppAreaType;
  objectId: number;
}

const ActionPanel = (props: Props) => {
  const { appArea, objectId } = props;
  const { showGetApp, showShare } = useModal();
  const { showPopup } = usePopup();
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const moreRef = useRef<HTMLDivElement>(null);

  const displayedIconIds = appArea === 'CAMPAIGN' ? [1, 2] : [1, 3];

  const displayGetApp = () => {
    return showGetApp({ appAreaType: appArea, objectId: objectId });
  };

  const displayShare = () => {
    if (appArea === 'CAMPAIGN') {
      return showShare({ shareType: 'CAMPAIGN_PUBLISHED', objectId: objectId });
    } else if (appArea === 'ITEM') {
      return showShare({ shareType: 'ITEM_FOR_SALE', objectId: objectId });
    } else {
      showShare();
    }
  };

  const renderReport = () => {
    return <ReportPopup appArea={appArea} objectId={objectId} />;
  };

  const actions = [
    {
      id: 1,
      onClick: displayShare,
      icon: ShareIcon,
      text: t('action-panel.share'),
      testId: 'share-button',
    },
    {
      id: 2,
      onClick: displayGetApp,
      icon: CommunityIcon,
      text: t('action-panel.community'),
      testId: 'community-button',
    },
    {
      id: 3,
      onClick: displayGetApp,
      icon: QRIcon,
      text: t('action-panel.qr'),
      testId: 'qr-button',
    },
    {
      id: 4,
      onClick: displayGetApp,
      icon: PosterIcon,
      text: t('action-panel.print-poster'),
      testId: 'print-poster-button',
    },
    {
      id: 5,
      onClick: () => showPopup(renderReport()),
      icon: ReportIcon,
      text: t('action-panel.report'),
      testId: 'report-button',
    },
  ];

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Container>
      {actions.map(
        action =>
          displayedIconIds.includes(action.id) && (
            <ActionItemWrapper key={action.id} onClick={action.onClick} data-testid={action.testId}>
              <img src={action.icon} alt={`${action.text} icon`} />
              <ActionText>{action.text}</ActionText>
            </ActionItemWrapper>
          )
      )}
      <MoreWrapper ref={moreRef} onClick={toggleShowMore} clicked={showMore} data-testid={'more-button'}>
        {showMore ? <img src={MoreIcon} alt='more icon' /> : <img src={MoreIconBlack} alt='more icon' />}
        <ActionText>{t('action-panel.more')}</ActionText>
      </MoreWrapper>
      {showMore && (
        <ActionPanelPopover onClose={() => setShowMore(false)} anchor={moreRef.current}>
          {actions.map(
            action =>
              !displayedIconIds.includes(action.id) && (
                <ActionItemWrapper key={action.id} onClick={action.onClick} data-testid={action.testId}>
                  <img src={action.icon} alt={`${action.text} icon`} />
                  <ActionText>{action.text}</ActionText>
                </ActionItemWrapper>
              )
          )}
        </ActionPanelPopover>
      )}
    </Container>
  );
};

export default ActionPanel;
