import { useTranslation } from 'react-i18next';

import { hotDealsBreakpointsConfig } from 'components/featured-section/featuredConfig';
import { FeaturedSection } from 'components/featured-section/FeaturedSection';
import { useFeaturedItems } from 'features/item/hooks/useItems';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';
import { useModal } from 'providers/ModalProvider';

import { CategoryTileSlider } from './categories/CategoryTileSlider';

export const HotDealsOverview = () => {
  const { t } = useTranslation('item');
  const { showGetApp } = useModal();
  const featuredItems = useFeaturedItems();
  const { pageState } = useHotDealsFilters();
  const isPageFiltered = pageState === 'Filtered';

  return (
    <>
      <FeaturedSection
        title={t('featured-banner.title')}
        description={t('featured-banner.desc')}
        buttonLabel={t('featured-banner.btn')}
        onButtonClick={() => showGetApp({ appAreaType: 'ADD_ITEM' })}
        state={featuredItems.state}
        variant={'ITEM'}
        pageState={pageState}
        breakPointConfig={hotDealsBreakpointsConfig}
        testId={'hot-deals'}
      />
      {!isPageFiltered && <CategoryTileSlider />}
    </>
  );
};
