import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RadioButton from 'components/buttons/RadioButton';
import { Text2, Text2Bold } from 'components/typography/Texts';
import { ProposedShippingAddressDto, UserShippingAddressRequestDto } from 'services/user/userService.dto';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  ${theme.mq.phone} {
    align-items: flex-start;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  ${theme.mq.phone} {
    flex-direction: column;
  }
`;

const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: pre;
`;

const StyledText = styled(Text2)<{ highlighted?: boolean }>`
  color: ${props => (props.highlighted ? theme.color.green : theme.color.darkerGray)};
  font-weight: ${props => (props.highlighted ? 'bold' : 'normal')};
`;

interface Props {
  shippingAddressRequest: UserShippingAddressRequestDto;
  proposedAddress?: ProposedShippingAddressDto;
  isSelected: boolean;
  onSelected: () => void;
}

export const ProposedShippingAddressDetails = ({
  shippingAddressRequest,
  proposedAddress,
  isSelected,
  onSelected,
}: Props) => {
  const renderProposedWord = (testId: string, initial?: string, proposed?: string) => {
    if (!proposed) return <StyledText data-testid={testId}>{initial}</StyledText>;
    const isDifferent = initial?.toLowerCase() !== proposed.toLowerCase();
    return (
      <StyledText data-testid={testId} highlighted={isDifferent}>
        {proposed}
      </StyledText>
    );
  };

  return (
    <Container data-testid={'proposed-shipping-address-details'}>
      <StyledRadioButton checked={isSelected} onChange={onSelected} />
      <DetailsContainer>
        <Text2Bold data-testid={'proposed-shipping-address-name'}>
          {shippingAddressRequest.firstName} {shippingAddressRequest.lastName}
        </Text2Bold>
        <StyledText data-testid={'proposed-shipping-address-address'}>
          {shippingAddressRequest.addressLine1}
          {shippingAddressRequest.addressLine2 ? ` ${shippingAddressRequest.addressLine2}` : ''},
        </StyledText>
        <SuggestionsWrapper>
          {renderProposedWord(
            'proposed-shipping-address-city',
            shippingAddressRequest.city,
            proposedAddress?.proposedCity
          )}
          <StyledText>, </StyledText>
          {renderProposedWord(
            'proposed-shipping-address-state',
            shippingAddressRequest.state,
            proposedAddress?.proposedState
          )}
          <StyledText> </StyledText>
          {renderProposedWord(
            'proposed-shipping-address-zip',
            shippingAddressRequest.postCode,
            proposedAddress?.proposedZipCodeLow
          )}
        </SuggestionsWrapper>
      </DetailsContainer>
    </Container>
  );
};
