import { messageService } from 'services/message/messageService';
import { MessageUrlsDto } from 'services/message/messageService.dto';

import { apiService } from './apiService';

interface UploadResult {
  imageUrl?: string;
}

class ImageUploadService {
  async uploadImageFromSrc(imgSrc: string, conversationId: number): Promise<UploadResult> {
    const tempUrl: MessageUrlsDto = await this.getTempUrl(conversationId);
    const file = this.base64ToFile(imgSrc);
    await apiService.put(tempUrl.presignedUrl, file, {}, { 'Content-Type': file.type });
    return { imageUrl: tempUrl.url };
  }

  async getTempUrl(conversationId: number): Promise<MessageUrlsDto> {
    const urls: MessageUrlsDto[] = await this.fetchNewTempUrls(conversationId);
    return urls[0];
  }

  async fetchNewTempUrls(conversationId: number): Promise<MessageUrlsDto[]> {
    const response = await messageService.generateMsgUrls(conversationId);
    return response.data;
  }

  base64ToFile = (base64: string): File => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image', { type: mime });
  };
}

const imageUploadService = new ImageUploadService();

export { imageUploadService };
